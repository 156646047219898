<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="text-center">No</th>
              <th colspan="10" class="text-center">Pendapatan Jasa</th>
              <th colspan="6" class="text-center">Biaya</th>
              <th rowspan="2" class="text-center">Laba Kotor</th>
              <th rowspan="2" class="text-center">% Laba Kotor</th>
            </tr>
            <tr>
              <th class="text-center">Nama Customer</th>
              <th class="text-center">Invoice#</th>
              <th class="text-center">PO#</th>
              <th class="text-center">Item#</th>
              <th class="text-center">Part Number</th>
              <th class="text-center">Part Description</th>
              <th class="text-center">Qty</th>
              <th class="text-center">U/M</th>
              <th class="text-center">Price/Unit</th>
              <th class="text-center">Total</th>
              <th class="text-center">Reff</th>
              <th class="text-center">Nama PIC</th>
              <th class="text-center">Qty</th>
              <th class="text-center">U/M</th>
              <th class="text-center">Price/Unit</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
              <td>13</td>
              <td>14</td>
              <td>15</td>
              <td>16</td>
              <td>17</td>
              <td>18</td>
              <td>19</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
